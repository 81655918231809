import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationRGE = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation RGE"
				description=""
				path="formations/formation-rge"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION RGE</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							nomFormation="RGE"
							titre="MAÎTRISE D’ŒUVRE RGE"
							duree="3 jours / 21 heures"
							prix="590 € HT"
							objectif={[
								"L’intégration des problématiques d’une enveloppe performante.",
								"La connaissance des solutions techniques d’une performance de l’enveloppe spécifique au projet de rénovation.",
								"La connaissance des risques de surchauffe / pathologies liés à l’environnement et au programme fonctionnel du projet.",
								"Le choix de l’outil d’aide au projet de rénovation thermique adéquat.",
								"L’explication des scenarios de rénovation de l’enveloppe appropriée et l’intégration de la rénovation par étapes."
							]}
							participants={[
								"Connaître les enjeux d’une rénovation de bâtiment concourant à la performance énergétique.",
								"Architectes, bureaux d’études, maîtres d’œuvre, maîtres d’ouvrage, entreprises du bâtiment, artisans, etc."
							]}
							preRequis={[
								"Aucune connaissance n’est obligatoire pour suivre cette formation.",
								"Avoir une maîtrise orale et écrite de la langue française.",
							]}
							programme={[
								"La réalisation d’un diagnostic énergétique global du bâti.",
								"La classification des pathologies liées à l’environnement et au programme fonctionnel existant.",
								"La présentation des besoins des usagers sur leur habitation.",
								"La rédaction du programme et conception d’une enveloppe performante.",
								"Le choix de l’outil de simulation thermique adéquat en fonction du projet bâti.",
								"La distinction et la connaissance des grands principes de rénovation de bâtiment selon leurs typologies.",
								"Étude de cas d’une conception d’enveloppe."
							]}
						></AccordionBase>
						<AccordionBase
							numberKey="1"
							titre="PROFESSIONNEL DU BÂTIMENT RGE"
							nomFormation="RGE"
							duree="3 jours / 21 heures"
							prix="590 € HT"
							objectif={[
								"Comprendre le fonctionnement énergétique dans le contexte du « PREH ».",
								"Connaître les principales technologies clés, les différentes solutions d’amélioration de la performance énergétique d’un bâtiment.",
								"Dans le cadre d’une approche globale, savoir appréhender et expliquer le projet de rénovation énergétique, en interprétant une évaluation énergétique."
							]}
							participants={[
								"Maîtriser les fondamentaux de son métier.",
								"Responsable technique de l’entreprise"
							]}
							preRequis={[
								"Aucune connaissance n’est obligatoire pour suivre cette formation.",
								"Avoir une maîtrise orale et écrite de la langue française.",
							]}
							programme={[
								"La connaissance des ordres de grandeur des postes de consommation d’énergie et des facteurs les impactant.",
								"La connaissance du contexte, des enjeux.",
								"L’identification des principaux risques en fonction des différents types de bâti et savoir les prévenir.",
								"Interprétation d’une évaluation énergétique et la connaissance des éléments de sensibilité.",
								"La connaissance des scénarios de rénovation, des bouquets de travaux efficaces énergétiquement et l’explication du bouquet de travaux retenu à son interlocuteur."
							]}
						></AccordionBase>
					</Accordion>
					<FooterFormation
						nomFormation="RGE"
						downloadLink={"RGE.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif=" La formation RGE (Reconnu Garant de l’Environnement) est une formation qui permet de sensibiliser les professionnels du bâtiment à la rénovation énergétique. Elle permet de comprendre les enjeux de la rénovation énergétique et de maîtriser les techniques de rénovation énergétique. La formation RGE est destinée aux professionnels du bâtiment qui souhaitent obtenir la qualification RGE pour pouvoir réaliser des travaux de rénovation énergétique."
					downloadLink="RGE.pdf"
					nomFormation="RGE"
					tauxSatisfaction="N / A"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationRGE
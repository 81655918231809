import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationTravauxHauteur = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Travaux en Hauteur"
				description="La formation Travaux en Hauteur vise à doter les participants des connaissances et compétences nécessaires pour travailler en toute sécurité en hauteur. Elle comprend des modules tels que le port du harnais de sécurité, l'entretien et la vérification des équipements, ainsi que des séances pratiques."
				path="formations/formation-travaux-hauteur"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION TRAVAUX EN HAUTEUR</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							titre="Harnais de Sécurité : Entretien et Vérification"
							nomFormation="Travaux en Hauteur"
							duree="1 jour / 7 heures"
							prix="190 € HT"
							objectif={[
								"Connaître et maîtriser la réglementation en vigueur pour la vérification du harnais de sécurité.",
								"Maîtriser les techniques de vérification.",
								"Connaître et respecter les règles d’entretien et de stockage des harnais de sécurité.",
								"Tenir et mettre à jour le registre de sécurité des vérifications et contrôles des harnais de sécurité."
							]}
							participants={[
								"Toute personne chargée d’entretenir et suivre les vérifications des équipements de travail en hauteur."
							]}
							programme={[
								"Rappels sur les conséquences d’une chute de hauteur.",
								"Le contexte réglementaire des équipements de protection individuelle.",
								"Présentation du harnais de sécurité.",
								"Apprentissage de la vérification.",
								"Exercices pratiques.",
								"Entretien et stockage.",
								"Registre de sécurité."
							]}
						/>
						<AccordionBase
							numberKey="1"
							titre="Le Port du Harnais de Sécurité"
							nomFormation="Travaux en Hauteur"
							duree="1 jour / 7 heures"
							prix="190 € HT"
							objectif={[
								"Connaître et identifier les risques liés aux travaux en hauteur.",
								"Connaître et maîtriser les principes de sécurité.",
								"Choisir les moyens de prévention et de protection.",
								"Appliquer les prescriptions de sécurité.",
								"Utiliser les équipements de protection individuelle."
							]}
							participants={[
								"Toute personne exécutant à titre professionnel, de façon exceptionnelle ou régulière, des travaux en hauteur."
							]}
							preRequis={[
								"Avoir 18 ans révolus.",
								"Être reconnu apte à l’exercice de travaux en hauteur par la Médecine du travail.",
								"Être obligatoirement muni des équipements de protection individuelle (EPI) : tenue de travail, gants, casque, harnais et chaussures de sécurité pour réaliser la mise en pratique de ce stage.",
								"Avoir suivi avec succès la formation “Entretien et vérification du harnais de sécurité”."
							]}
							programme={[
								"Réglementation, responsabilités.",
								"Les obligations de l’employeur, du salarié, des fabricants.",
								"Moyens de protection collective.",
								"Moyens de protection individuelle.",
								"Présentation de l’équipement.",
								"Application sur site en exercices pratiques."
							]}
						/>
					</Accordion>
					<FooterFormation
						nomFormation="Travaux en Hauteur"
						downloadLink={"TH.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif="
					Cette formation propose deux modules essentiels : Port du Harnais de Sécurité et Entretien du Harnais. Ces modules visent à fournir aux participants une expertise complète, allant de l'utilisation correcte du harnais de sécurité aux procédures d'entretien nécessaires pour garantir son bon fonctionnement."
					downloadLink="TH.pdf"
					nomFormation="Travaux en Hauteur"
					tauxSatisfaction="N / A"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationTravauxHauteur
import React from "react";

const SchoolStats = () => {
  return (
    <div className="card text-center shadow-sm" style={{ maxWidth: "100%", margin: "10px auto" }}>
      <div className="card-header bg-primary text-white">
        <h5 className="mb-0">AGENCE SCHOOLAMIANTE</h5>
      </div>
      <div className="card-body">
        <p style={{ textAlign: "left" }}>
            <strong>Taux de réussite :</strong> <span className="text-success">95%</span>
        </p>
        <ul>
            <li><small>Encadrant chantier : 93%</small></li>
            <li><small>Encadrant technique : 87%</small></li>
            <li><small>Opérateur chantier : 100%</small></li>
        </ul>
        <p style={{ textAlign: "left" }}>
          <strong>Taux de satisfaction :</strong> <span className="text-warning">86%</span>
        </p>
      </div>
    </div>
  );
};



export default SchoolStats;

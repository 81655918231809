import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationPlomb = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation risque plomb"
				description="La formation plomb vise à sensibiliser les participants aux risques liés à l’exposition au plomb, et à leur fournir les connaissances nécessaires pour prévenir les risques et protéger leur santé."
				path="formations/formation-plomb"
			></HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION RISQUE PLOMB</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="0">
						<AccordionBase
							numberKey="0"
							nomFormation="Plomb"
							titre="Sensibilisation aux risques liés au plomb"
							duree="1 jour / 7 heures"
							prix="290 € HT"
							tauxReussite="93"
							objectif={[
								"La formation vise à sensibiliser les participants aux risques liés à l’exposition au plomb, et à leur fournir les connaissances nécessaires pour prévenir les risques et protéger leur santé.",
							]}
							participants={[
								"La formation est destinée aux professionnels du BTP, et a pour objectif de préparer et proposer des solutions à la présence de plomb sur le chantier",
							]}
							preRequis={[
								"Aptitude médicale",
								"Etre agé de 18 ans minimum",
							]}
							programme={[
								"Le plomb dans le bâtiment : historique, utilisation et structure",
								"Pathologies provoquées par le plomb",
								"Réaction et gestion du risque plomb"
							]}
						></AccordionBase>
					</Accordion>
					<FooterFormation
						nomFormation="Plomb"
						downloadLink={"PLOMB.pdf"}
					></FooterFormation>
				</div>
				<FormationText
					objectif="La formation plomb vise à sensibiliser les participants aux risques liés à l’exposition au plomb, et à leur fournir les connaissances nécessaires pour prévenir les risques et protéger leur santé."
					downloadLink="PLOMB.pdf"
					nomFormation="Plomb"
					tauxSatisfaction="88"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationPlomb
import React from 'react';

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

import AccordionBase from '../../components/accordion';
import Accordion from 'react-bootstrap/Accordion';

import FormationText from '../../components/formation-text';

import '../formations-page.css'
import HelmetWidget from '../../components/helmet';
import FooterFormation from '../../components/footer-formation';

const FormationSS4 = () => {
	return (
		<div className="formations-container">
			<HelmetWidget
				pageTitle="Formation Amiante SS4"
				description="La formation sous section 4 (SS4) prépare les participants à intervenir en toute sécurité dans des chantiers susceptibles de libérer des fibres d'amiante, avec un accent sur la réglementation, les procédures opérationnelles, et une mise à jour triennale pour maintenir les compétences."
				path="formations/formation-amiante-ss4"
			>
			</HelmetWidget>
			<Navbar></Navbar>
			<div className="home-hero titre-formation">
				<h1 className="title-text">FORMATION AMIANTE SS4</h1>
			</div>
			<div className="content-div">
				<div className="accordions-div">
					<Accordion defaultActiveKey="100">
						<AccordionBase
							numberKey="0"
							nomFormation="SS4"
							titre="FORMATION INITIALE | OPERATEUR DE CHANTIER"
							duree="2 jours / 14 heures"
							tauxReussite="99"
							prix="490 € HT"
							objectif={[
								"Appliquer la réglementation et connaître les pratiques de prévention avant toute intervention sur des matériaux ou appareils susceptibles de libérer des fibres d'amiante.",
								"Être capable de faire appliquer les procédures pour les interventions sur des matériaux contenant de l'amiante."
							]}
							participants={[
								"La formation est destinée aux employeurs et travailleurs qui réalisent des travaux susceptibles de provoquer l'émission de fibre d'amiante - décret du 4 mai 2012.",
								"Le personnel opérateur de chantier comprend tout travailleur chargé d'exécuter des travaux et/ou d'installer, de faire fonctionner et d'entretenir les matériels qui lui sont confiés, dans le respect des procédures, du plan de retrait ou de confinement, ou du mode opératoire - décret du 23 février 2012, art.2 al.7."
							]}
							preRequis={[
								"Maîtrise orale et écrite de la langue française.",
								"Aptitude médicale au poste de travail."
							]}
							programme={[
								"Analyse critique d'un repérage de l'amiante.",
								"Équipements de protection collective (EPC) et équipements de protection individuelle (EPI).",
								"Les situations d'urgence et/ou anormales, l'information et le savoir-faire sur la prévention des risques liés à l'amiante."
							]}
							recyclage="Attention : Un recyclage de l'attestation est à effectuer tous les 3 ans. "
						/>
						{/* <AccordionBase
							numberKey="1"
							duree="5 jours / 35 heures"
							nomFormation="SS4"
							prix="890 € HT"
							titre="FORMATION INITIALE | ENCADRANT DE CHANTIER"
							objectif={[
								"Être capable de définir la conduite à tenir dans les situations d'urgence et de la faire appliquer.",
								"Informer et former les salariés.",
								"Être capable de faire appliquer les procédures pour les interventions sur des matériaux contenant de l'amiante."
							]}
							participants={[
								"La formation est destinée aux employeurs et travailleurs qui réalisent des travaux susceptibles de provoquer l'émission de fibre d'amiante - décret du 4/05/2012.",
								"Le personnel opérateur de chantier comprend tout travailleur chargé d'exécuter des travaux et/ou d'installer, de faire fonctionner et d'entretenir les matériels qui lui sont confiés, dans le respect des procédures, du plan de retrait ou de confinement, ou du mode opératoire - décret du 23 février 2012, art.2 al.7."
							]}
							preRequis={[
								"Maîtrise orale et écrite de la langue française.",
								"Aptitude médicale au port d'équipement de protection respiratoire."
							]}
							programme={[
								"Opérations spécifiques de l'activité exercée pouvant entraîner la libération de fibres d'amiante et les niveaux d'exposition et d'empoussièrement induits.",
								"Principes généraux de ventilation et de captage des poussières à la source.",
								"Appliquer et de faire appliquer un mode opératoire d'intervention sur des matériaux contenant de l'amiante.",
								"Définir les processus et modes opératoires d'intervention à partir de l'évaluation des risques.",
								"Assurer le suivi et les documents liés aux opérations."
							]}
						/> */}
						<AccordionBase
							numberKey="2"
							nomFormation="SS4"
							titre="FORMATION INITIALE | ENCADRANT TECHNIQUE"
							duree="5 jours / 35 heures"
							prix="890 € HT"
							tauxReussite="100"
							objectif={[
								"Être capable de définir la conduite à tenir dans les situations d'urgence et de la faire appliquer.",
								"Informer et former les salariés.",
								"Être capable de faire appliquer les procédures pour les interventions sur des matériaux contenant de l'amiante."
							]}
							participants={[
								"La formation est destinée aux employeurs et travailleurs qui réalisent des travaux susceptibles de provoquer l'émission de fibre d'amiante - décret du 4/05/2012.",
								"Le personnel opérateur de chantier comprend tout travailleur chargé d'exécuter des travaux et/ou d'installer, de faire fonctionner et d'entretenir les matériels qui lui sont confiés, dans le respect des procédures, du plan de retrait ou de confinement, ou du mode opératoire - décret du 23 février 2012, art.2 al.7."
							]}
							preRequis={[
								"Maîtrise orale et écrite de la langue française.",
								"Aptitude médicale au port d'équipement de protection respiratoire."
							]}
							programme={[
								"Caractéristiques et propriétés de l'amiante.",
								"Produits, dispositifs et matériaux susceptibles de contenir de l'amiante.",
								"Les opérations spécifiques de l'activité exercée pouvant entraîner la libération de fibres d'amiante.",
								"Assurer le suivi et les documents liés aux opérations.",
								"Dispositifs pénaux encourus par l'employeur.",
								"Évaluation des risques.",
								"Équipements de protection collective (EPC) et équipements de protection individuelle (EPI).",
								"Gestion des déchets contenant de l'amiante."
							]}
						/>
						<AccordionBase
							numberKey="3"
							nomFormation="SS4"
							titre="FORMATION INITIALE | ENCADRANT CUMUL DE FONCTION"
							duree="5 jours / 35 heures"
							tauxReussite="87"
							prix="890 € HT"
							objectif={[
								"Être capable de définir la conduite à tenir dans les situations d'urgence et de la faire appliquer.",
								"Informer et former les salariés.",
								"Être capable de faire appliquer les procédures pour les interventions sur des matériaux contenant de l'amiante."
							]}
							participants={[
								`Travailleur ayant, au sein de l'entreprise, les compétences nécessaires pour diriger et
								coordonner l'exécution des travaux, mettre en œuvre le mode opératoire.`,
								`Employeur et tout travailleur possédant, au sein de l'entreprise, une responsabilité au niveau
								des prises de décisions technico-commerciales, des études, de l'établissement des documents
								techniques ou contractuels, de la définition, de l'organisation et de la mise en œuvre des
								spécifications et des moyens techniques.`
							]}
							preRequis={[
								`Fournir à l’organisme de formation un document attestant l’aptitude médicale au poste de travail
								du travailleur, délivré par le médecin du travail de l’entreprise. Ce document prend en compte les
								spécificités relatives au port des équipements de protection respiratoire.`
							]}
							programme={[
								"Caractéristiques et propriétés de l'amiante.",
								"Évaluation des risques.",
								"Produits, dispositifs et matériaux susceptibles de contenir de l'amiante.",
								"Exigences réglementaires et risque pénal relatifs à l'élimination des déchets amiantés.",
								"Équipements de protection collective (EPC) et équipements de protection individuelle (EPI).",
								"Gestion des déchets contenant de l'amiante.",
								"Les situations d'urgence, l'information et le savoir-faire sur la prévention des risques liés à l'amiante.",
								"Connaître les principes généraux de ventilation et de captage des poussières à la source.",
								"Être capable d'appliquer et/ou de faire appliquer des procédures adaptées aux interventions."
							]}
						/>
						<AccordionBase
							numberKey="4"
							nomFormation="SS4"
							titre="FORMATION RECYCLAGE TRIENNAL | OC - EC - ET- ECF"
							duree="1 jour / 7 heures"
							tauxReussite="97"
							prix="290 € HT"
							objectif={[
								"Mise à jour des connaissances en tenant compte de l'évolution des techniques et de la réglementation.",
								"Retour d'expérience.",
								"Renforcer les aspects de prévention liés aux risques liés à l'amiante.",
								"Être capable d'établir et d'appliquer les procédures opératoires spécifiques au type d'activité exercée pour la préparation, la réalisation, la restitution des chantiers et les procédures de contrôle en cours de chantier."
							]}
							participants={[
								"La formation est destinée aux employeurs et travailleurs effectuant des interventions susceptibles de générer un risque amiante.",
								"Les employeurs et travailleurs auront, au préalable, suivi la formation initiale « Risque amiante sous-section 4 »."
							]}
							preRequis={[
								"Attestation de formation initiale « Risque amiante sous-section 4 ».",
							]}
							programme={[
								"Remise à niveau des connaissances, identification des évolutions techniques.",
								"Retour d'expérience.",
								"Remise à niveau des références législatives et réglementaires.",
								"Analyse des besoins des participants.",
								"Élaboration et application des modes opératoires.",
								"Spécifique aux personnels encadrants.",
								"Spécifique aux personnels opérateurs."
							]}
						/>
					</Accordion>
					<FooterFormation
						nomFormation="SS4"
						downloadLink={""}
					></FooterFormation>
				</div>
				<FormationText
					objectif="
					La formation SS4 prépare les participants à intervenir en toute sécurité dans des chantiers susceptibles de libérer des fibres d'amiante, avec un accent sur la réglementation, les procédures opérationnelles, et une mise à jour triennale pour maintenir les compétences."
					downloadLink="SS4.pdf"
					nomFormation="SS4"
					tauxSatisfaction="92"
				></FormationText>
			</div>
			<Footer></Footer>
		</div>
	)
}

export default FormationSS4

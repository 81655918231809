import React from "react";
import '../views/formations-page.css'
import ImageWithLoading from "./image-loader";
import propTypes from 'prop-types';
import SchoolStats from "./school_stats";

const FooterFormation = (props) => {

    function downloadBrochure() {
		window.open("/brochures/" + props.downloadLink, '_blank');
	}

    return (
        <div className="div-certif-download">
            <div className="row-certification">
                <ImageWithLoading src="/external/certibat-certif.png" alt="certibat-certif" className="certif-icon" />
                <ImageWithLoading src="/external/qualiopi.png" alt="qualiopi-certif" className="certif-icon" />
            </div>
            { props.downloadLink != "" &&(<div className="d-flex flex-column justify-content-center">
				<button onClick={downloadBrochure} className="btn btn-primary">Télécharger la brochure</button>
            </div>)}
            {props.nomFormation == "SS3" && (<div><SchoolStats /></div>)}
        </div>
    )
}

FooterFormation.defaultProps = {
	downloadLink: "RE.pdf",
	nomFormation: "",
}

FooterFormation.propTypes = {
	downloadLink: propTypes.string,
	nomFormation: propTypes.string,
}

export default FooterFormation;
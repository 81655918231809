import React from 'react';
import './acces_component.css'; // Ajoutez un fichier CSS pour styliser le composant
import {faCar, faBus, faSubway } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const AccessComponent = () => {
  return (
    <div className="access-container">
      <h2>ACCÈS</h2>
      
      <div className="access-line">
        <FontAwesomeIcon icon={faCar} />
        <p><strong>En voiture :</strong><br /> A86 / D115<br />Parking à 3 min à pied</p>
      </div>

      <div className="access-line">
        <FontAwesomeIcon icon={faBus} />
        <p><strong>Bus :</strong><br /> 234 arrêt République - Division Leclerc<br />151 arrêt Henri Gautier</p>
      </div>

      <div className="access-line">
        <FontAwesomeIcon icon={faSubway} />
        <p><strong>Tram / Métro :</strong><br /> Métro 7 - arrêt Fort d'Aubervilliers (20 min à pied)<br />T1 - arrêt Escadrille Normandie Niemen (15 min à pied)</p>
      </div>
    </div>
  );
};

export default AccessComponent;
